import { motion } from "framer-motion";
import tw from "twin.macro";
import React from "react";

const Container = tw(motion.div)`
px-10 my-4 py-4 bg-white rounded-lg shadow-md w-full h-full
`;

const DateBadgeContainer = tw.div`
flex justify-between items-center 
`;

const Date = tw.span`
font-light text-gray-600 font-bold 
`;

const Badge = tw.a`
px-2 py-1 text-gray-100 font-bold rounded hover:bg-gray-500 
`;

const index = ({ post }) => {
  const { date, title, content, language } = post;
  return (
    <Container
      whileHover={{
        scale: 1.05,
        transition: { duration: 0.7 },
      }}
      whileTap={{ scale: 0.9 }}
    >
      <DateBadgeContainer>
        <Date>{date}</Date>
        <Badge href="">{language}</Badge>
      </DateBadgeContainer>
      <div className="mt-2">
        <p
          className="text-2xl text-gray-700 font-bold hover:text-gray-600"
          href="#"
        >
          {title}
        </p>
        <p className="mt-2 text-gray-600">{content}</p>
      </div>
      <div className="flex justify-between items-center mt-4">
        <a className="text-blue-600 hover:underline" href="#">
          Read more
        </a>
      </div>
    </Container>
  );
};

export default index;
