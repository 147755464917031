const posts = [
  {
    key: 1,
    title: "Hello UK?",
    date: "11 Oct 2021",
    content:
      "Finally I am out of the 10 day quarantine and finally breathing fresh British air. I'm excited for school this year and hoping to learn more",
    language: "Python",
  },
  {
    key: 2,
    title: "Test",
    date: "3rd March 2020",
    content: "testasdfsd",
    language: "Python",
  },
  {
    key: 3,
    title: "Test",
    date: "3rd March 2020",
    content: "testasdfsd",
    language: "Python",
  },
  {
    key: 4,
    title: "Test",
    date: "3rd March 2020",
    content: "testasdfsd",
    language: "Python",
  },
  {
    key: 5,
    title: "Test",
    date: "3rd March 2020",
    content: "testasdfsd",
    language: "Python",
  },
  {
    key: 6,
    title: "Test",
    date: "3rd March 2020",
    content: "testasdfsd",
    language: "Python",
  },
  {
    key: 7,
    title: "Test",
    date: "3rd March 2020",
    content: "testasdfsd",
    language: "Python",
  },
];

export default posts;
