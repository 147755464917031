import React from "react";
import Page from "../components/Page";
import posts from "../data/posts";
import Post from "../components/Post";

const BlogPage = ({ location }) => {
  return (
    <Page location={location}>
      <div className="grid mx-4 my-12 auto-cols-fr auto-rows-max md:grid-cols-2">
        {posts.map((post) => (
          <div key={post.key} className="mx-4 my-4">
            <Post post={post} />
          </div>
        ))}
      </div>
    </Page>
  );
};

export default BlogPage;
